.modal-unit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure modal is on top of other content */
}

.modal-content-unit {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80%; /* Limit the height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.modal-content-unit h2 {
  margin-bottom: 20px;
}

.modal-content-unit ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0;
  list-style: none;
  margin: 0;
}

.modal-content-unit li {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
}

.modal-content-unit button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
