.report-details-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.report-details-modal-content {
  padding: 20px;
  position: relative;
}

.report-details-modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.report-details-info {
  margin-bottom: 20px;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-details-modal-actions {
  display: flex;
  justify-content: space-around;
}

.report-details-overdue-button {
  cursor: pointer;
}
