/* Table */
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  /* Table Head */
  .table thead th {
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Remove this line */
  }
  
  /* Table Body */
  .table tbody td {
    padding: 10px;
  }
  
  /* Alternating Row Background Color */
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Hover Effect */
  .table tbody tr:hover {
    background-color: #f5f5f5;
  }
  