/* CSS */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 90%; /* Adjusted max-width to 90% */
    max-height: 90%; /* Added max-height to limit modal height */
    overflow-y: auto; /* Add scrollbar when content exceeds modal height */
}

.modal-content {
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 40px;
    color: black;
}

.payment-methods label,
.file-upload {
    margin-top: 20px;
}

.payment-methods input[type="radio"] {
    margin-right: 10px;
}

#submit-paid {
    height: 54px;
    width: 100%; /* Adjusted width to 100% */
    background-color: #0D1142;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 40px;
}

#payment-type-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
}

.file-upload {
    margin-top: 20px;
}

.file-upload h3 {
    margin-bottom: 5px;
}

.custom-file-upload {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
}

.custom-file-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.file-name {
    margin-top: 5px;
    display: block;
}

.file-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Changed flex-direction to column */
    width: 100%; /* Adjusted width to 100% */
    border: dotted black 2px;
    margin-top: 20px;
}

.content-wrapper {
    display: flex;
    align-items: center;
}

.clear-file {
    margin-top: 10px; /* Adjusted margin-top */
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}
