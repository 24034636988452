* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.part1 {
  background: linear-gradient(
    188deg,
    #0085ff -6.28%,
    #2832e4 30.34%,
    #0d0a96 75.08%
  );
  flex: 0.2;
}
.part1-img-container {
  padding-top: 152px;
  padding-left: 43px;
  padding-right: 71px;
  padding-bottom: 40px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
