.properties-container {
    /* height: 258px; */
    /* width: 587px; */
    border-Radius: 20px;
    padding: 24px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}