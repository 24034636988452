.property {
    /* height: 54px; */
    /* width: 380px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.property-img {
    width: 150px;
    /* Set the fixed width for the container */
    height: 150px;
    /* Set the fixed height for the container */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Ensures nothing spills out of the container */
    border-radius: 8px;
    /* Optional: rounded corners */
    background-color: #f5f5f5;
    /* Optional: light background for empty containers */
}

.property-img img {
    width: 100%;
    /* Make image fill the container */
    height: 100%;
    /* Maintain the container's fixed dimensions */
    object-fit: contain;
    /* Ensures image scales proportionally to fit without cropping */
    object-position: center;
    /* Center the image inside the container */
}


.unit-details {
    /* background-color: aqua; */
    /* padding-left: 20px; */
    /* padding-right: 100px; */
}

.graph-container {
    /* width: 177px; */
    /* height: 200px; */
    /* background-color: green; */
}